.container {
  margin: "16px 0px";
}

.listEmail {
  padding: "16px 0px";
  margin: "16px 0px";
  background-color: "#7150CC1A";
  border-radius: "16px";
}
.emailTag {
  display: "flex";
}
.emailButton {
  margin: "8px";
}
.inputDiv {
  display: flex;
  margin: "0px 8px 0px 0px";
}
.input {
  margin: "0px 8px 0px 0px";
}
