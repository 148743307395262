.App {
  width: 100%;
  height: 100%;
  /* background-color: hsl(0deg 0% 0% / 81%) !important; */
  /* color: white; */
  /* overflow-y: hidden; */
}

html {
  overflow: hidden;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

html::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Animaciones */

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition:
    width 0.5s 0.5s,
    height 0.5s 0.5s,
    opacity 0.5s;
  transition: all 0.5s;
}

.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition:
    width 0.5s,
    height 0.5s,
    opacity 0.5s 0.25s;
  transition: all 0.5s;
}

.fadeOut {
  right: -100% !important;
}

.fadeIn {
  right: 0 !important;
}

.left-menu {
  overflow: auto;
  transition: all 0.5s;
  overflow: hidden;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.left-menu::-webkit-scrollbar {
  display: none;
}
