/* para modificar las animaciones esta en App.css en la transicion */

.mainContainer {
  position: fixed;
  top: 9%;
  right: 0;
  width: 30rem;
  height: 80vh;
  margin-right: 2rem;
  background-color: white;
  z-index: 2;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 5px;
  color: black;
  overflow-y: scroll;
}
.mainContainer::-webkit-scrollbar {
  display: none;
}
.mainContainer > header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.25rem 2rem;
  background-color: #5aa1cf;
  border-radius: 5px 5px 0 0;
}
.mainContainer > header h5 {
  margin: 0px;
  text-align: left;
}
.mainContainer > header i {
  margin-left: auto;
  height: 20px;
  top: 0px;
  position: relative;
  font-size: 35px;
  font-weight: bolder;
  margin-top: -30px;
}

/* Container contents */
.containerContents {
  place-content: center;
  padding: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.containerContents > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(124, 124, 124);
}
.containerContents > div img {
  width: 100%;
  object-fit: contain;
}
.containerContents > div > p {
  font-size: small;
  align-self: flex-start;
}
.containerContents > div > button {
  border: 0px;
  padding: 1.5rem 1rem;
  border-radius: 5px;
  background-color: aquamarine;
  transition: all 0.15s ease;
  font-weight: 500;
}
.containerContents > div > button:hover {
  background-color: rgba(127, 255, 212, 0.766);
  border-radius: 10px;
}

/* Parrafo info */
.infoCity {
  padding: 1.5rem;
}
.infoCity > p {
  text-align: justify;
  color: rgb(124, 124, 124);
  height: 10rem;
  overflow-y: auto;
  margin: 0px;
}

.buttonsParagraph {
  display: flex;
  margin: 1rem 1.5rem;
  justify-content: space-between;
}
.buttonsParagraph > button {
  background-color: transparent;
  border: 0px;
  border-bottom: aquamarine 2px solid;
  transition: all 0.2s ease;
}
.buttonsParagraph > button:hover {
  background-color: aquamarine;
  height: auto;
}

/* Link */
.interestLink {
  display: flex;
  margin: 1.5rem;
  margin-bottom: 0.75rem;
}
.interestLink > p {
  color: rgb(124, 124, 124);
}
.interestLink a {
  text-decoration: none;
}

.shareBtnContainer {
  display: flex;
  padding: 1.5rem;
  padding-top: 0rem;
}
.shareBtnContainer * {
  font-size: 37px;
  color: rgb(54, 160, 213);
}
.shareBtnContainer > i {
  margin-right: 1rem;
  cursor: pointer;
}
.shareBtnContainer > div {
  margin-left: auto;
}
.shareBtnContainer > div > i {
  margin-left: 1rem;
  font-size: 45px;
  cursor: pointer;
}

@media (max-width: 750px) {
  .mainContainer {
    width: 80%;
    height: 75%;
    margin: 0 2rem;
  }
  .shareBtnContainer * {
    font-size: 25px;
    color: rgb(54, 160, 213);
  }
  .shareBtnContainer > div > i {
    margin-left: 1rem;
    font-size: 30px;
    cursor: pointer;
  }
}
