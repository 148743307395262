.content {
  min-height: 64px;
  width: 100%;
  display: block;
}

@media (max-width: 600px) {
  .logo {
    display: none;
  }
}
