div.lorawan {
  background-color: #6179ff;
}

div.decoder {
  background-color: #ff61fa;
}

div.door {
  background-color: #ff9368;
}

div.project {
  background-color: #ffef61;
}

div.gateway {
  background-color: #66ff61;
}

div.sensor {
  background-color: #61ffe5;
}

div.server {
  background-color: #ff6161;
}

div.users {
  background-color: #bdff61;
}

div.logger {
  background-color: #ca61ff;
}
