.buttonCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  height: 100vh;
  padding: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper::-webkit-scrollbar {
  display: none;
}
