.tab {
  min-height: 50px;
  width: 100%;
  padding: 25px;
  font-family: sans-serif;
  color: #444;
}
.main {
  border-radius: 15px;
  /* background: #353535; */
  margin: 15px;
}
.inline {
  display: flex;
  padding: 10px;
}

.noSelected {
  padding: 10px;
  cursor: pointer;
}
.selected {
  padding: 10px;
}
ul.inline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
  li {
    display: inline-block;
    margin-left: 0;
    padding: 10px;
    border-bottom: 2px solid #eee;
    transition: all 0.5s;
    font-family: sans-serif;
    font-weight: 300;
    cursor: pointer;
    color: #aaa;
    &.selected {
      border-bottom: 2px solid #337ab7;
      color: #444;
    }
  }
}
